import React from 'react';
import { Grid } from '@material-ui/core';
import { useForm } from "react-hook-form";
import Button from '@material-ui/core/Button';
import { useHistory } from "react-router-dom";
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import { useStateMachine } from "little-state-machine";
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

import { appColors, buttonStyle, innerContainer, topMargin } from './shared/styles';
import { eMailPattern, postData, setLocalStorage } from '../helper';
import { updateAuthState } from './actions';

interface FormContent {
    email: string;
    password: string;
}

export const Login: React.FC<any> = (props) => {
    const classes = useStyles();
    const history = useHistory();
    const { action, state } = useStateMachine(updateAuthState);
    const [showErrorMsg, setShowErrorMsg] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const { register, handleSubmit, errors } = useForm<FormContent>();

    React.useEffect(() => {
        setTimeout(() => {
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        }, 1);
        if (state.auth.loggedIn) {
            history.push('/admin');
        }
    }, [state.auth, history]);

    const onSubmit = (data: FormContent) => {
        setShowErrorMsg(false);
        setLoading(true);
        postData('login', data)
            .then(data => {
                if (data.token) {
                    setLocalStorage(data.token);
                    action({ loggedIn: true });
                    history.push('/admin');
                } else {
                    setLoading(false);
                    setShowErrorMsg(true);
                }
            }).catch(error => {
                setLoading(false);
                setShowErrorMsg(true);
            });
    };

    return (
        <Container maxWidth="sm">
            <form onSubmit={handleSubmit(onSubmit)}>
                <Grid container spacing={1} className={classes.root}>
                    <Grid container item xs={12}>
                        <Typography variant="h4" component="h3" className={classes.fullWidthText}>
                            Login
                        </Typography>
                    </Grid>
                    <Grid container item xs={12}>
                        {showErrorMsg && (
                            <Typography variant="caption" className={classes.errorMessage}>Email or password invalid</Typography>
                        )}
                    </Grid>
                    <Grid container item xs={12}>
                        <input name="email" ref={register({ required: true, pattern: eMailPattern })} className={classes.input} placeholder="email" disabled={loading} />
                        {errors.email && (
                            <Typography variant="caption" className={classes.errorText}>Check email</Typography>
                        )}
                    </Grid>
                    <Grid container item xs={12}>
                        <input name="password" type="password" ref={register({ required: true, minLength: 6 })} className={classes.input} placeholder="password" disabled={loading} />
                        {errors.password && (
                            <Typography variant="caption" className={classes.errorText}>Minimum length 6 characters</Typography>
                        )}
                    </Grid>
                    <Button
                        type="submit"
                        disabled={loading}
                        variant="contained"
                        className={classes.button}>
                        Login
                    </Button>
                </Grid>
            </form>

        </Container>
    );
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            ...topMargin,
            ...innerContainer,
        },
        fullWidthText: {
            margin: '15px 0',
            color: appColors.dark,
            textTransform: 'uppercase',
        },
        input: {
            width: '100%',
            padding: 10,
            marginBottom: 10,
        },
        errorText: {
            fontSize: 10,
            color: 'red',
        },
        errorMessage: {
            fontSize: 14,
            color: 'red',
        },
        button: {
            ...buttonStyle(),
            marginLeft: 'auto',
        },
    }),
);