import React from "react";
import draftToHtml from "draftjs-to-html";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw } from "draft-js";

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { makeStyles } from "@material-ui/core/styles";
import { createStyles } from "@material-ui/core/styles";
import { Theme } from "@material-ui/core/styles";
import { editorOptions } from "../../helper";

export const AppEditor: React.FC<any> = ({ onChange, placeholder }) => {
  const classes = useStyles();
  const [editorState, setEditorState] = React.useState(() =>
    EditorState.createEmpty()
  );

  React.useEffect(() => {
    onChange(draftToHtml(convertToRaw(editorState.getCurrentContent())));
  }, [editorState]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={classes.root}>
      <Editor
        placeholder={placeholder}
        editorState={editorState}
        toolbar={editorOptions}
        handlePastedText={() => false}
        onEditorStateChange={setEditorState}
      />
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      "& .rdw-editor-wrapper": {
        border: "1px solid rgba(0, 0, 0, 0.12)",
        marginBottom: 20,
      },
      "& .rdw-editor-main": {
        backgroundColor: "#fff",
      },
      "& .DraftEditor-root": {
        padding: "0 1rem",
      },
      "& .draft-editor-wrapper": {
        border: "1px solid #ccc",
      },
    },
  })
);
