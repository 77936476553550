import React from 'react';
import { Grid } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';

import { innerContainer, topMargin } from '../shared/styles';
import { AppHeader } from '../shared/AppHeader';
import { ResourceFolder } from './ResourceFolder';

export const Resources: React.FC<any> = (props) => {
    const classes = useStyles();

    return (
        <React.Fragment>
            <AppHeader header="Resources" />
            <Container maxWidth="lg">
                <Grid container className={classes.root}>
                    <ResourceFolder />
                </Grid>
            </Container>
        </React.Fragment>
    );
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            ...topMargin,
            ...innerContainer,
        },
        divider: {
            width: '100%',
        },
        textCenter: {
            width: '100%',
            marginBottom: 30,
            textAlign: 'center',
        }
    }),
);