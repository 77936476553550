import React from 'react';
import { Grid } from '@material-ui/core';
import { useForm, Controller } from "react-hook-form";
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

import { appColors, buttonStyle, innerContainer, topMargin } from './shared/styles';
import { postData } from '../helper';
import { AppEditor } from './shared/AppEditor';

interface FormContent {
    position: string;
    requirements: string;
    introduction: string;
}

export const AddCareer: React.FC<any> = (props) => {
    const classes = useStyles();
    const [showErrorMsg, setShowErrorMsg] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const { register, handleSubmit, errors, reset, control } = useForm<FormContent>();

    const onSubmit = (data: FormContent) => {
        setShowErrorMsg(false);
        setLoading(true);
        postData('jobs', data)
            .then(data => {
                if (data.success) {
                    reset();
                    props.fetchCareer();
                    window.scrollTo({
                        top: 0,
                        behavior: 'smooth'
                    });
                    setLoading(false);
                } else {
                    setLoading(false);
                    setShowErrorMsg(true);
                }
            }).catch(error => {
                setLoading(false);
                setShowErrorMsg(true);
            });
    };

    return (
        <Container id="add-career">
            <form onSubmit={handleSubmit(onSubmit)}>
                <Grid container spacing={1} className={classes.root}>
                    <Grid container item xs={12}>
                        <Typography variant="h6" component="h3" className={classes.fullWidthText}>
                            Add Career
                        </Typography>
                    </Grid>
                    <Grid container item xs={12}>
                        {showErrorMsg && (
                            <Typography variant="caption" className={classes.errorMessage}>Error, login again and retry!</Typography>
                        )}
                    </Grid>
                    <Grid container item xs={12}>
                        <input name="position" ref={register({ required: true })} className={classes.input} placeholder="Position" disabled={loading} />
                        {errors.position && (
                            <Typography variant="caption" className={classes.errorText}>Field is required</Typography>
                        )}
                    </Grid>
                    <Grid container item xs={12}>
                        <Controller
                            name="introduction"
                            control={control}
                            defaultValue={false}
                            render={({ onChange }) => <AppEditor placeholder="Details" onChange={onChange} />}
                        />
                    </Grid>
                    <Button
                        type="submit"
                        disabled={loading}
                        variant="contained"
                        className={classes.button}>
                        Post advert
                    </Button>
                </Grid>
            </form>

        </Container>
    );
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            ...topMargin,
            ...innerContainer,
        },
        fullWidthText: {
            margin: '15px 0',
            color: appColors.dark,
            textTransform: 'uppercase',
        },
        input: {
            width: '100%',
            padding: 10,
            marginBottom: 10,
        },
        errorText: {
            fontSize: 10,
            color: 'red',
        },
        errorMessage: {
            fontSize: 14,
            color: 'red',
        },
        button: {
            ...buttonStyle(),
            marginLeft: 'auto',
        },
        label: {
            fontSize: 12,
            letterSpacing: 1,
            fontWeight: 600,
            opacity: '70%',
            lineHeight: 2,
        },
    }),
);