import React, { Fragment } from 'react';
import { Grid } from '@material-ui/core';
import Rating from '@material-ui/lab/Rating';
import Button from '@material-ui/core/Button';
import Skeleton from '@material-ui/lab/Skeleton';
import DeleteIcon from '@material-ui/icons/Delete';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import { useStateMachine } from 'little-state-machine';
import { createStyles, makeStyles, Theme, withStyles } from '@material-ui/core/styles';

import { AddReview } from './AddReview';
import { postData, url } from '../helper';
import { isOnAdminRoute, updateAuthState } from './actions';
import { NothingHere } from './shared/NothingHere';
import { appColors, buttonStyle, innerContainer, topMargin } from './shared/styles';
import { AppHeader } from './shared/AppHeader';

interface ReviewContent {
    _id: string;
    name: string;
    stars: number;
    message: string;
    created?: string;
};

const StyledRating = withStyles({
    iconFilled: {
        color: appColors.dark,
    },
})(Rating);

function Loader() {
    return (
        <Grid container item xs={12}>
            <Skeleton variant="text" width="50%" />
            <Skeleton variant="rect" width="100%" height={118} />
            <Skeleton variant="text" width="100%" />
        </Grid>
    );
}

export const Reviews: React.FC<any> = (props) => {
    const classes = useStyles();
    const [reviews, setReviews] = React.useState([]);
    const [loading, setLoading] = React.useState(true);
    const { state } = useStateMachine(updateAuthState);
    const [showErrorMsg, setShowErrorMsg] = React.useState<null | string>(null);

    React.useEffect(() => {
        fetchReview();
    }, []);

    function fetchReview() {
        fetch(`${url}reviews`)
            .then(response => response.json())
            .then(data => {
                setReviews(data.reverse());
                setLoading(false);
            }).catch(err => {
                setLoading(false);
            });
    }

    function onDelete(id: string) {
        setShowErrorMsg(null);
        postData(`reviews/${id}`, {}, 'delete')
            .then(data => {
                if (data.success) {
                    const newReviews = reviews.filter((review: ReviewContent) => review._id !== id);
                    setReviews([...newReviews]);
                } else {
                    setLoading(false);
                    setShowErrorMsg(id);
                }
            }).catch(error => {
                setLoading(false);
                setShowErrorMsg(id);
            });
    }

    const header = (stars: number, id: string) => (
        <div className={classes.header}>
            <StyledRating value={stars} readOnly />
            {(state.auth.loggedIn && isOnAdminRoute()) && (loading ? (
                <Typography variant="caption">'Please wait...'</Typography>
            ) : (
                    <DeleteIcon style={{ fontSize: 40 }} onClick={() => onDelete(id)} />
                ))}
        </div>
    );

    function review(arg: ReviewContent) {
        return (
            <Grid container key={arg._id} item xs={12} className={classes.post}>
                {(showErrorMsg === arg._id) && <Typography variant="caption" gutterBottom>Post could not  be deleted, login again and retry!</Typography>}
                {header(arg.stars, arg._id)}
                <Typography className="body1" variant="body1" gutterBottom>
                    {arg.message}
                </Typography>
                <Typography className="body2" variant="caption" gutterBottom>
                    {arg.name}
                </Typography>
            </Grid>
        )
    }

    return (
        <Fragment>
            <AppHeader header="Reviews">
                <Button
                    variant="contained"
                    className={classes.buttonStyle}
                    onClick={() => {
                        document.getElementById('add-review')?.scrollIntoView({
                            behavior: "smooth",
                        });
                    }}>
                    Add a review
                </Button>
            </AppHeader>
            <Container maxWidth="lg" id="resources">
                <Grid container spacing={1} className={classes.root}>
                    {(!loading && reviews.length === 0) && <NothingHere message='There are no reviews yet, be the first to add your review' />}
                    {loading ? (
                        [1, 2, 3].map(v => <Loader key={v} />)
                    ) : (
                        reviews.map(data => review(data))
                    )}
                </Grid>
            </Container>
            <AddReview fetchReview={fetchReview} />
        </Fragment>
    );
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            ...topMargin,
            ...innerContainer,
            '& >div': {
                margin: '10px 0',
                display: 'flex',
                justifyContent: 'space-between',
            },
        },
        fullWidthText: {
            color: appColors.dark,
            textTransform: 'uppercase',
        },
        textCenter: {
            width: '100%',
            marginBottom: 30,
            textAlign: 'center',
        },
        post: {
            marginBottom: 15,
            padding: '20px !important',
            borderRadius: 5,
            backgroundColor: appColors.lightest,
            '& .body1': {
                width: '100%',
                marginBottom: 10,
                paddingBottom: 10,
                borderBottom: `solid 1px ${appColors.light}`,
                borderBottomStyle: 'dashed',
            },
            '& .body2': {
                width: '100%',
                textAlign: 'right',
            }
        },
        header: {
            width: '100%',
            display: 'flex',
            borderBottomStyle: 'dashed',
            justifyContent: 'space-between',
            borderBottom: `solid 1px ${appColors.light}`,
            '& >svg': {
                fontSize: '30px !important',
                cursor: 'pointer',
                color: appColors.dark,
                '&:hover': {
                    opacity: .7,
                },
            },
        },
        buttonStyle: buttonStyle(true),
    }),
);