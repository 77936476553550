import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Theme, makeStyles, createStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';

export interface SelectOption {
    label: string;
    value: string;
    icon: string;
    level?: number;
};

interface Props {
    value: any;
    label: string;
    error?: boolean;
    options: SelectOption[];
    onChange: (value: any) => void;
}


export const AppSelect: React.FC<Props> = ({ options, value, label, error = false, onChange }) => {
    
    const classes = useStyles();
    const id = uuidv4();
    const getValue = () => value || options[0].value;

    return (
        <FormControl variant="outlined" className={classes.root}>
            <InputLabel htmlFor={id}>{label}</InputLabel>
            <Select
                native
                error={error}
                label={label}
                value={getValue()}
                onChange={(event) => onChange(event.target.value)}
                inputProps={{
                    id: id,
                }}
            >
                {options.map(option => <option key={option.value} value={option.value}>{option.label}</option>)}
            </Select>
        </FormControl>
    );
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
        },
    }),
);