import React from 'react';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

import { TeamCard } from '../shared/TeamCard';
import { appColors, innerContainer, topMargin } from '../shared/styles';

export const Team: React.FC<any> = (props) => {
    const classes = useStyles();

    React.useEffect(() => {
        if (props.location.hash === '#team') {
            const element = document.getElementById('team');
            setTimeout(() => {
                element?.scrollIntoView({
                    behavior: "smooth",
                }); 
            }, 1);
            
        }
    }, [props.location.hash]);


    return (
        <Container maxWidth="lg" id="team">
            <Grid container spacing={3} className={classes.root}>
                <Typography variant="h4" component="h2" className={classes.header}>
                    Meet the team
                </Typography>
                <TeamCard />
            </Grid>
        </Container>
    );
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            ...topMargin,
            ...innerContainer,
        },
        header: {
            width: '100%',
            fontWeight: 900,
            textAlign: 'center',
            margin: '40px 0 10px',
            color: appColors.dark,
            textTransform: 'uppercase',
        }
    }),
);