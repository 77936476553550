import React from 'react';
import { Grid } from '@material-ui/core';
import { useForm } from "react-hook-form";
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

import { appColors, buttonStyle, innerContainer, topMargin } from './shared/styles';
import { eMailPattern, postData } from '../helper';
import Rating from '@material-ui/lab/Rating';

interface FormContent {
    message: string;
    email: string;
    name: string;
}

export const AddReview: React.FC<any> = (props) => {
    const classes = useStyles();
    const [showErrorMsg, setShowErrorMsg] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [stars, setStars] = React.useState(5);
    const { register, handleSubmit, errors, reset } = useForm<FormContent>();

    const onSubmit = (data: FormContent) => {
        setShowErrorMsg(false);
        setLoading(true);
        postData('reviews', {...data, stars})
            .then(data => {
                console.log(data);
                
                if (data.success) {
                    reset();
                    setStars(5);
                    props.fetchReview();
                    window.scrollTo({
                        top: 0,
                        behavior: 'smooth'
                    });
                    setLoading(false);
                } else {
                    setLoading(false);
                    setShowErrorMsg(true);
                }
            }).catch(error => {
                setLoading(false);
                setShowErrorMsg(true);
            });
    };

    return (
        <Container id="add-review" maxWidth="sm">
            <form onSubmit={handleSubmit(onSubmit)}>
                <Grid container spacing={1} className={classes.root}>
                    <Grid container item xs={12}>
                        <Typography variant="h6" component="h3" className={classes.fullWidthText}>
                            Share your view about us!
                        </Typography>
                    </Grid>
                    <Grid container item xs={12}>
                        {showErrorMsg && (
                            <Typography variant="caption" className={classes.errorMessage}>Error, login again and retry!</Typography>
                        )}
                    </Grid>
                    <Grid container item xs={12}>
                        <Typography variant="caption" className={classes.rateUs}>Rate us</Typography>
                        <Rating
                            name="rate us"
                            value={stars}
                            onChange={(event, newValue) => {
                                setStars(Number(newValue));
                            }} />
                    </Grid>
                    <Grid container item xs={12}>
                        <input
                            name="email"
                            className={classes.input}
                            placeholder="E-mail"
                            disabled={loading}
                            ref={register({ required: true, pattern: eMailPattern })} />
                        {errors.email && (
                            <Typography variant="caption" className={classes.errorText}>Email is not correct</Typography>
                        )}
                    </Grid>
                    <Grid container item xs={12}>
                        <input name="name" ref={register({ required: true })} className={classes.input} placeholder="Full Name" disabled={loading} />
                        {errors.name && (
                            <Typography variant="caption" className={classes.errorText}>Field is required</Typography>
                        )}
                    </Grid>
                    <Grid container item xs={12}>
                        <textarea rows={10} name="message" ref={register({ required: true })} className={classes.input} placeholder="Message" disabled={loading} />
                        {errors.message && (
                            <Typography variant="caption" className={classes.errorText}>Field is required</Typography>
                        )}
                    </Grid>
                    <Button
                        type="submit"
                        disabled={loading}
                        variant="contained"
                        className={classes.button}>
                        Post review
                    </Button>
                </Grid>
            </form>

        </Container>
    );
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            ...topMargin,
            ...innerContainer,
        },
        fullWidthText: {
            margin: '15px 0',
            color: appColors.dark,
            textTransform: 'uppercase',
        },
        input: {
            width: '100%',
            padding: 10,
            marginBottom: 10,
        },
        errorText: {
            fontSize: 10,
            color: 'red',
        },
        errorMessage: {
            fontSize: 14,
            color: 'red',
        },
        button: {
            ...buttonStyle(),
            marginLeft: 'auto',
        },
        rateUs: {
            fontSize: 16,
            marginRight: 10,
        },
    }),
);