
import React, { Fragment } from 'react';
import Slider from "react-slick";
import Typography from '@material-ui/core/Typography/Typography';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

import man from '../../images/man.jpg';
import equipment from '../../images/equipment.jpg';

export const AppSlider: React.FC<any> = (props) => {
    const classes = useStyles();

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        fade: false,
        ease: true,
        autoplay: true,
        autoplaySpeed: 4000,
        slidesToShow: 1,
        slidesToScroll: 1,
    };

    return (
        <div className={classes.root}>
            <Slider {...settings}>
                {[man, equipment].map((image, index) => (
                    <Fragment key={index}>
                        <div className={classes.imageDiv} style={{ backgroundImage: `url(${image})` }}></div>
                        <img className={classes.imageTag} key={index} alt="" src={image} />
                    </Fragment>
                ))}
            </Slider>
            <div className={classes.header}>
                <Typography variant="h4" component="h1">
                    Fademos
                </Typography>
                <Typography variant="h5" component="h2">
                    Family practice
                </Typography>
            </div>
        </div>
    );
}

const headerText = {
    fontWeight: 900,
    color: '#fff',
    letterSpacing: 1,
    textAlign: 'center',
    textTransform: 'uppercase',
    textShadow: '#000 1px 0 10px',
};

const useStyles = makeStyles((theme: Theme) => createStyles({
    root: {
        position: 'relative',
        '& .slick-list': {
            maxHeight: '90vh',
        },
        '& .slick-next': {
            right: 10,
            zIndex: 1,
        },
        '& .slick-prev': {
            left: 10,
            zIndex: 1,
        },
        '& .slick-dots': {
            display: 'none !important',
        },
        '& img': {
            [theme.breakpoints.down('xs')]: {
                minWidth: '200%',
            }
        }
    },
    header: {
        left: 0,
        right: 0,
        bottom: 90,
        position: 'absolute',
        '& h1': headerText,
        '& h2': headerText,
    },
    imageDiv: {
        display: 'none',
        [theme.breakpoints.down('sm')]: {
            display: 'block',
            height: '90vh',
            backgroundSize: 'cover',
            backgroundPosition: '50%',
        },
    },
    imageTag: {
        display: 'none !important',
        [theme.breakpoints.up('md')]: {
            display: 'block !important',
            width: '100%',
        },
    },
}));