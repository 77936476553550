import { AppSnackBarContent } from "./shared/AppSnackBar";

export function updateAuthState(state: any, payload: any) {
    return {
        ...state,
        auth: {
            ...state.auth,
            ...payload
        }
    };
}

export function updateResourceState(state: any, payload: any) {
    if (payload.data) {
        payload.data = payload.data.map((load: any) => ({ ...load, ...(load.data ? JSON.parse(load.data) : {}), data: null }));
    }
    return {
        ...state,
        resources: payload,
    };
}

export function updateMessagesState(state: any, payload: AppSnackBarContent | Array<AppSnackBarContent>) {
    let updatedArray = payload as Array<AppSnackBarContent>
    if (!Array.isArray(payload)) {
        let payloadUsed = false;
        updatedArray = state.messages.map((item: AppSnackBarContent) => {
            if (item._id === payload._id) {
                payloadUsed = true;
                return payload; //you'd need to add a key attribute to the data
            } else {
                return item;
            }
        });
        if (!payloadUsed) {
            updatedArray.push(payload);
        }
    }


    return {
        ...state,
        messages: [
            ...(updatedArray as Array<AppSnackBarContent>)
        ]
    };
}

export function updateArticles(state: any, payload: any) {
    if (payload.data) {        
        payload = payload.data.map((load: any) => ({ ...load, ...(load.data ? JSON.parse(load.data) : {}), data: null }));
    }
    return {
        ...state,
        articles: payload,
    };
}


export function updateUiMessage(state: any, payload: any) {
    return {
        ...state,
        uiMessage: payload.message,
    };
}

export const isOnAdminRoute = () => window.location.href.includes('admin');
