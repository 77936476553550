import { createMuiTheme } from "@material-ui/core/styles";

const defaultTheme = createMuiTheme();

const mobileOveride = {
    paddingRight: '5px !important',
    paddingLeft: '5px !important',
}
export const theme = createMuiTheme({
    overrides: {
        MuiContainer: {
            maxWidthLg: {
                [defaultTheme.breakpoints.down('xs')]: mobileOveride,
            },
        },
        MuiGrid: {
            "spacing-xs-1": {
                [defaultTheme.breakpoints.down('xs')]: mobileOveride,
            },
        },
    }
});