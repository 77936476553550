import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

import { appColors, innerContainer, textContainer } from '../shared/styles';
import { Hours } from '../shared/Hours';

export const Statements: React.FC<any> = (props) => {
    const classes = useStyles();

    return (
        <Container maxWidth="lg" className={classes.root}>
            <Grid container spacing={1} className={classes.root}>
                <Grid container item xs={12} md={4} className={classes.mission}>
                    <Typography variant="h6" component="h3" gutterBottom>
                        Mission
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        Our mission is to treat individuals and families as a unique entity with dignity and respect.
                        Ensure that every individual and family understand the disease process and health promotion to their care.
                    </Typography>
                </Grid>
                <Grid container item xs={12} md={4} className={classes.vision}>
                    <Typography variant="h6" component="h3" gutterBottom>
                        Vision
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        Our vision is to be the world’s best health center that enables and transforms the way
                        patients relate to their health and well-being. Envision bridging the gap in health care and reducing health disparity.
                    </Typography>
                </Grid>
                <Grid container item xs={12} md={4} className={classes.hour}>
                    <Hours />
                </Grid>
            </Grid>
        </Container>
    );
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            ...innerContainer,
            marginTop: '-60px',
            position: 'relative',
        },
        mission: {
            opacity: 0.9,
            ...textContainer,
            background: appColors.light,
        },
        vision: {
            opacity: 0.9,
            ...textContainer,
            background: appColors.medium,
        },
        hour: {
            opacity: 0.9,
            padding: '0 !important',
        },
    }),
);