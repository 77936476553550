import React from 'react';
import { format } from 'date-fns'
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import LoopIcon from '@material-ui/icons/Loop';
import { useStateMachine } from 'little-state-machine';

import { postData } from '../../helper';
import { updateArticles, updateUiMessage } from '../actions';
import { Link } from 'react-router-dom';

export interface MediaCardContent {
  _id: string;
  header: string;
  message: string;
  link: string;
  created: string;
  canDelete?: boolean;
};

export const MediaCard: React.FC<MediaCardContent> = ({ _id, header, message, link, created, canDelete }) => {
  const classes = useStyles();
  const [loading, setLoading] = React.useState(false);
  const { state: { articles }, actions } = useStateMachine({
    updateArticles,
    updateUiMessage
  });

  const textToDipslay = () => message.split(' ', 30).join(' ') + '...';
  const dateOfPublication = () => format(new Date(created), 'yyyy-MM-dd');

  const deleteItem = () => {
    setLoading(true);
    postData(`resources/${_id}`, {}, 'delete').then(res => {
      if (res.success) {
        let updatedArticles = articles;
        updatedArticles = updatedArticles.filter((data: any) => data._id !== _id);
        actions.updateArticles(updatedArticles);
        setLoading(false);
        actions.updateUiMessage({ message: { message: 'Success', type: 'success' } });
      } else
        actions.updateUiMessage({ message: { message: 'Error, try again', type: 'error' } });
    }).catch(err => {
      setLoading(false);
      actions.updateUiMessage({ message: { message: 'Error, try again', type: 'error' } });
    });
  }


  return (
    <Card className={classes.root}>
      <CardActionArea>
        <CardMedia
          className={classes.media}
          image={link}
          title={header}
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="h2">
            {header}
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p" gutterBottom>
            {dateOfPublication()}
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p" dangerouslySetInnerHTML={{ __html: textToDipslay() }} />
        </CardContent>
      </CardActionArea>
      <CardActions className={classes.action}>
        <Button size="small" color="primary" component={Link} to={{
          pathname: 'article/sdsdsdsd',
          state: {header, message, link, created}
        }}>
          Read full article
        </Button>
        {canDelete && (
          <IconButton aria-label="delete" onClick={() => deleteItem()} className={classes.deleteBtn}>
            {loading ? (
              <LoopIcon fontSize="small" />
            ) : (
                <DeleteIcon fontSize="small" />
              )}
          </IconButton>
        )}
      </CardActions>
    </Card>
  );
}

const useStyles = makeStyles({
  root: {
    width: '100%',
    marginBottom: 30,
  },
  media: {
    height: 140,
  },
  action: {
    borderTop: 'solid 1px grey',
  },
  deleteBtn: {
    color: 'red',
    marginLeft: 'auto !important',
  }
});
