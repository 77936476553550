import React from 'react';
import Grid from '@material-ui/core/Grid/Grid';
import WorkOffIcon from '@material-ui/icons/WorkOff';
import Typography from '@material-ui/core/Typography';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

import { appColors } from './styles';

export const NothingHere: React.FC<{message: string}> = ({message}) => {
    const classes = useStyles();
    return (
        <Grid container item xs={12} className={classes.post}>
            <Typography variant="h4">{message}</Typography>
            <WorkOffIcon style={{ fontSize: 40 }} />
        </Grid>
    )
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        post: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent:'center !important',
            alignItems: 'center',
            height: 'calc(100vh - 300px)',
            padding: '20px !important',
            borderRadius: 5,
            backgroundColor: appColors.lightest,
            color: '#fff',
            '& .body1': {
                width: '100%',
                marginBottom: 10,
                paddingBottom: 10,
                borderBottom: `solid 1px ${appColors.light}`,
                borderBottomStyle: 'dashed',
            },
            '& .body2': {
                width: '100%',
                textAlign: 'right',
            }
        },
    }),
);