import React, { Fragment } from "react";
import clsx from "clsx";
import AppBar from "@material-ui/core/AppBar";
import MenuIcon from "@material-ui/icons/Menu";
import Toolbar from "@material-ui/core/Toolbar";
import CloseIcon from "@material-ui/icons/Close";
import Container from "@material-ui/core/Container";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import { useStateMachine } from "little-state-machine";
import { NavLink, useHistory } from "react-router-dom";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import PhoneIcon from "@material-ui/icons/Phone";
import Hidden from "@material-ui/core/Hidden";
import Button from "@material-ui/core/Button";

import { appColors } from "./shared/styles";
import { tokenName } from "../helper";
import { updateAuthState } from "./actions";

const menus = [
  {
    name: "Resources",
    link: "resources",
  },
  {
    name: "Career",
    link: "career",
  },
  {
    name: "Review",
    link: "reviews",
  },
  {
    name: "215-613-5069",
    link: "215-613-5069",
    type: "btn",
  },
];

export const Menu: React.FC<any> = () => {
  const classes = useStyles();
  const history = useHistory();
  const [isActive, setIsActive] = React.useState(false);
  const { action, state } = useStateMachine(updateAuthState);
  const doLogOut = (e: any) => {
    e.preventDefault();
    setIsActive(false);
    localStorage.removeItem(tokenName);
    action({ loggedIn: false });
    history.push("/");
  };
  const adminLinks = () => (
    <Fragment>
      <Typography
        variant="subtitle1"
        component="span"
        onClick={() => setIsActive(false)}
      >
        <NavLink activeClassName="active" className={classes.link} to="/admin">
          Admin
        </NavLink>
      </Typography>
      <Hidden smUp>
        <Divider />
      </Hidden>
      <Typography
        variant="subtitle1"
        component="span"
        onClick={(e: any) => doLogOut(e)}
      >
        <NavLink activeClassName="active" className={classes.link} to="/logout">
          Logout
        </NavLink>
      </Typography>
    </Fragment>
  );

  const bugerMenu = () => (isActive ? <CloseIcon /> : <MenuIcon />);

  return (
    <div className={classes.root}>
      <AppBar position="static" className={classes.appBar}>
        <Container maxWidth="lg">
          <Toolbar>
            <NavLink
              activeClassName="active"
              className={clsx([classes.link, classes.icon])}
              to="/"
              exact={true}
            >
              <img src="/icon.png" alt="FademosFP" height="85px" />
            </NavLink>

            <Hidden xsDown>
              {menus.map((menu) => {
                return menu.type === "btn" ? (
                  <Button
                    key={menu.name}
                    variant="outlined"
                    className={classes.callBtn}
                    startIcon={<PhoneIcon />}
                    href={`tel:${menu.link}`}
                  >
                    {menu.name}
                  </Button>
                ) : (
                  <Typography
                    key={menu.name}
                    variant="subtitle1"
                    component="span"
                  >
                    <NavLink
                      activeClassName="active"
                      className={classes.link}
                      to={`/${menu.link}`}
                    >
                      {menu.name}
                    </NavLink>
                  </Typography>
                );
              })}
              {state.auth.loggedIn && adminLinks()}
            </Hidden>
            <Hidden smUp>
              <Button
                variant="outlined"
                className={classes.callBtn}
                startIcon={<PhoneIcon />}
                href={`tel:215-613-5069`}
              >
                215-613-5069
              </Button>
              <IconButton
                className={classes.button}
                onClick={() => setIsActive(!isActive)}
              >
                {bugerMenu()}
              </IconButton>
            </Hidden>
          </Toolbar>
          <Hidden smUp>
            {isActive && (
              <List component="nav" className={classes.navList}>
                {menus.map((menu, index) => {
                  return menu.type === "btn" ? null : (
                    <React.Fragment key={menu.name}>
                      <Typography
                        variant="subtitle1"
                        component="span"
                        onClick={() => setIsActive(false)}
                      >
                        <NavLink
                          activeClassName="active"
                          className={classes.link}
                          to={`/${menu.link}`}
                        >
                          {menu.name}
                        </NavLink>
                      </Typography>
                      {index + 1 === menus.length &&
                      !state.auth.loggedIn ? null : (
                        <Divider />
                      )}
                    </React.Fragment>
                  );
                })}
                {state.auth.loggedIn && adminLinks()}
              </List>
            )}
          </Hidden>
        </Container>
      </AppBar>
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      "& span": {
        cursor: "pointer",
        marginRight: 10,
      },
    },
    appBar: {
      background: appColors.light,
    },
    link: {
      color: "#fff",
      opacity: 0.7,
      textDecoration: "none",
      "&:hover": {
        opacity: 1,
        fontWeight: "bold",
      },
      "&.active": {
        opacity: 1,
        fontWeight: "bold",
      },
    },
    icon: {
      opacity: 1,
      marginRight: "auto !important",
      "&:hover": {
        opacity: 0.7,
      },
      "& img": {
        margin: "10px 0",
      },
    },
    button: {
      color: "#fff",
      padding: 0,
      "& span": {
        margin: 0,
      },
    },
    navList: {
      "& span": {
        padding: 16,
      },
      "& hr": {
        margin: "10px 0",
      },
    },
    callBtn: {
      marginRight: 10,
      color: "#fff",
      borderColor: "#fff",
      opacity: 0.7,
      borderRadius: 50,
      border: "2px solid",
    },
  })
);
