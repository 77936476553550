import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import { TransitionProps } from "@material-ui/core/transitions";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import Container from "@material-ui/core/Container";
import {
  createStyles,
  makeStyles,
  Theme,
  WithStyles,
  withStyles,
} from "@material-ui/core/styles";

import ListSubheader from "@material-ui/core/ListSubheader";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import EmailOutlinedIcon from "@material-ui/icons/EmailOutlined";
import PhoneInTalkTwoToneIcon from "@material-ui/icons/PhoneInTalkTwoTone";

import { appColors } from "./styles";

interface AppointmentProps {
  showDialog: boolean;
  setShowDialog: () => void;
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const Appointment: React.FC<AppointmentProps> = ({
  showDialog,
  setShowDialog,
}) => {
  const classes = useStyles();

  return (
    <Dialog
      open={showDialog}
      TransitionComponent={Transition}
      keepMounted
      fullScreen
      onClose={setShowDialog}
      classes={{
        paper: classes.paper,
      }}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <Container maxWidth="sm">
        <DialogTitle
          id="customized-dialog-title"
          classes={{ root: classes.dialogTitle }}
          onClose={setShowDialog}
        >
          Schedule an appointment
        </DialogTitle>
        <DialogContent>
          <List
            component="nav"
            aria-labelledby="nested-list-subheader"
            className={classes.root}
          >
            <a href="tel: 215-613-5069">
              <ListItem className={classes.listItem}>
                <ListItemIcon>
                  <PhoneInTalkTwoToneIcon />
                </ListItemIcon>
                <ListItemText primary="215-613-5069" />
              </ListItem>
            </a>
            <a href="mailto: info@fademosfp.com?subject=Appointment booking from web!">
              <ListItem className={classes.listItem}>
                <ListItemIcon>
                  <EmailOutlinedIcon />
                </ListItemIcon>
                <ListItemText primary="info@fademosfp.com" />
              </ListItem>
            </a>
            <div>
              <a
                href="https://www.zocdoc.com/practice/fademos-family-practice-68749?lock=true&isNewPatient=false&referrerType=widget"
                target="_top"
                title="Fademos Family Practice "
              >
                <img
                  src="https://offsiteSchedule.zocdoc.com/images/remote/zd_bookonline_162x48.png"
                  alt="Fademos Family Practice "
                  title="Fademos Family Practice "
                  className={classes.bookOnline}
                />
              </a>
            </div>
          </List>
        </DialogContent>
      </Container>
    </Dialog>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      "& a": {
        textDecoration: "none",
      },
    },
    paper: {
      background: appColors.light,
    },
    dialogTitle: {
      alignItems: "center",
      color: "#FFF",
    },
    listItem: {
      color: "#fff",
      cursor: "pointer",
      "& svg": { color: "#fff" },
      "& span": { fontWeight: "bold" },
      "&:hover": {
        opacity: 0.5,
      },
    },
    bookOnline: {
      border: 0,
      borderRadius: 5,
      marginLeft: 19,
      marginTop: 10,
    },
  })
);

interface DialogTitleProps extends WithStyles<typeof styles> {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
}

const styles = (theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      marginTop: 30,
      padding: theme.spacing(2),
      justifyContent: "space-between",
    },
    closeButton: {
      color: "#FFF",
    },
    listItem: {},
  });

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6" display="inline">
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});
