import React from 'react';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';

import { appColors, innerContainer, topMargin } from '../shared/styles';
import covid from '../../images/covid.jpg';

export const Intro: React.FC<any> = (props) => {
    const classes = useStyles();
    return (
        <Container maxWidth="lg">
            <Grid container spacing={1} className={classes.root}>
                <Grid container item xs={12} md={8}>
                    <Typography paragraph>
                        Fademos Family Practice provides comprehensive, high quality primary care for families and individuals of ALL ages. We help manage diabetes, high cholesterol and high blood pressure.
                    </Typography>
                    <Typography paragraph className={classes.fullWidthText}>
                        We are staffed by nurse practitioners. We provide friendly convenient care designed to meet patients needs. We also offer bilingual capabilities with translation services for all languages.
                    </Typography>
                    <Typography paragraph className={classes.fullWidthText}>
                        We provide services for all pa medicaid hmo's, and privately insured patients we also provide patient care to the uninsured and the underinsured: we accept all major credit cards.
                    </Typography>
                </Grid>
                <Grid container item xs={12} md={4}>
                    <div className={classes.imageDiv} />
                </Grid>
            </Grid>
        </Container>
    );
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            ...topMargin,
            ...innerContainer,
            flexDirection: 'row',
            [theme.breakpoints.down('sm')]: {
                flexDirection: 'column-reverse;',
            },
        },
        fullWidthText: {
            width: '100%',
            margin: '15px 0',
        },
        listHeader: {
            color: appColors.dark,
        },
        list: {
            margin: 0,
        },
        imageDiv: {
            width: '100%',
            height: '100%',
            backgroundSize: 'cover',
            backgroundPosition: '60%',
            backgroundImage: `url(${covid})`,
            [theme.breakpoints.down('sm')]: {
                height: '70vh',
            },
        }
    }),
);