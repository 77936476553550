import React from 'react';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import { useStateMachine } from 'little-state-machine';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

import { AddArticle } from './AddArticle';
import { isOnAdminRoute } from '../actions';
import { MediaCard, MediaCardContent } from '../shared/MediaCard';
import { appColors, innerContainer, topMargin } from '../shared/styles';

export const Articles: React.FC<any> = () => {
    const classes = useStyles();
    const { state: { auth, articles } } = useStateMachine();
    const isCorrectUser = () => auth.loggedIn && isOnAdminRoute();

    return (
        <Container maxWidth="lg">
            {isCorrectUser() && <AddArticle />}
            <Grid container spacing={1} className={classes.root}>
                <Grid container item xs={12}>
                    {articles.length > 0 && (
                        <Typography variant="h4" component="h2" className={classes.header}>
                            Articles
                        </Typography>
                    )}
                </Grid>
                {articles.map((tile: MediaCardContent) => (
                    <Grid key={tile._id} container item xs={12} sm={6} md={4}>
                        <MediaCard {...tile} canDelete={isCorrectUser()} />
                    </Grid>
                ))}
            </Grid>
        </Container>
    )
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            ...topMargin,
            ...innerContainer,
        },
        header: {
            width: '100%',
            fontWeight: 900,
            textAlign: 'center',
            margin: '40px 0 10px',
            color: appColors.dark,
            textTransform: 'uppercase',
        },
    }),
);