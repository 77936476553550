import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import React from 'react';
import draftToHtml from 'draftjs-to-html';
import { Editor } from 'react-draft-wysiwyg';
import { useDropzone } from 'react-dropzone';
import Button from '@material-ui/core/Button';
import { useStateMachine } from 'little-state-machine';
import { EditorState, convertToRaw } from 'draft-js';
import LinearProgress from '@material-ui/core/LinearProgress';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

import { editorOptions, postData } from '../../helper';
import { buttonStyle } from '../shared/styles';
import { updateArticles, updateUiMessage } from '../actions';

export const AddArticle: React.FC<any> = () => {
    const classes = useStyles();
    const [file, setFile] = React.useState<any>(null);
    const [header, setHeader] = React.useState('');
    const [loading, setLoading] = React.useState(false);
    const { state: { articles }, actions } = useStateMachine({
        updateArticles,
        updateUiMessage
    });
    const { getRootProps, getInputProps } = useDropzone({
        minSize: 0,
        maxFiles: 1,
        multiple: false,
        accept: 'image/jpeg, image/png',
        onDrop: (acceptedFiles: any) => {
            setFile(acceptedFiles[0]);
        }
    });
    const [editorState, setEditorState] = React.useState(() => EditorState.createEmpty());

    const toBase64 = (file: any) => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });

    async function saveData() {
        const data = {
            header,
            file: file ? await toBase64(file) : null,
            message: draftToHtml(convertToRaw(editorState.getCurrentContent())),
        };

        setLoading(true);
        postData('articles', data).then(res => {            
            if (res.success) {
                setHeader('');
                setFile(null);
                setEditorState(() => EditorState.createEmpty());
                const updatedArticles = articles;
                updatedArticles.push({ ...res.data, ...(JSON.parse(res.data.data)) });
                actions.updateArticles(updatedArticles);
                actions.updateUiMessage({ message: { message: 'Success', type: 'success' } });
            } else {
                actions.updateUiMessage({ message: { message: 'Error, try again', type: 'error' } });
            }
            setLoading(false);
        }).catch(err => {
            console.log(err);
            
            actions.updateUiMessage({ message: { message: 'Error, try again', type: 'error' } });
            setLoading(false);
        });
    }


    return (
      <div className="add-resource">
        {loading && <LinearProgress />}
        <input
          name="email"
          value={header}
          onChange={(event) => setHeader(event.target.value)}
          placeholder="Header"
        />
        <div className="file-container">
          <div {...getRootProps({ className: "dropzone" })}>
            <input {...getInputProps()} />
            <p>Drag 'n' drop some files here, or click to select files</p>
          </div>
          <div className="aside">
            {file && (
              <p>
                {file.path} - {file.size} bytes
              </p>
            )}
          </div>
        </div>
        <Editor
          editorState={editorState}
          toolbar={editorOptions}
          handlePastedText={() => false}
          onEditorStateChange={setEditorState}
        />
        <Button
          type="submit"
          variant="contained"
          onClick={saveData}
          disabled={loading}
          className={classes.button}
        >
          Save
        </Button>
      </div>
    );
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        button: {
            ...buttonStyle(),
            marginLeft: 'auto',
            display: 'block',
            marginBottom: 40,
        },
    }),
);