import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import DeleteIcon from "@material-ui/icons/Delete";
import Typography from "@material-ui/core/Typography";
import { useStateMachine } from "little-state-machine";
import { updateMessagesState } from "../actions";

export interface AppSnackBarContent {
  _id: string;
  message: string;
  header?: string;
  seen: boolean;
  onDelete?: (id: string) => void;
}

export const AppSnackBar: React.FC<AppSnackBarContent> = (props) => {
  const classes = useStyles();

  return (
    <Snackbar
      className={classes.root}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={!props.seen}
      message={<Message {...props} />}
    />
  );
};

export const Message: React.FC<AppSnackBarContent> = (props) => {
  const classes = useStyles();
  const { action } = useStateMachine(updateMessagesState);

  const onClose = () => {
    if (props.onDelete) {
      props.onDelete(props._id);
      return;
    }
    action({ ...props, seen: true });
    const checked = sessionStorage.getItem("messages");
    const closedMessages = checked ? JSON.parse(checked) : [];
    sessionStorage.setItem(
      "messages",
      JSON.stringify([...closedMessages, props._id])
    );
  };

  return (
    <div>
      <div>
        <IconButton
          aria-label="close"
          color="inherit"
          className={classes.close}
          onClick={onClose}
        >
          {props.onDelete ? <DeleteIcon /> : <CloseIcon />}
        </IconButton>
      </div>
      {props.header && (
        <div className={classes.heading}>
          <Typography variant="h6" component="span">
            {props.header}
          </Typography>
        </div>
      )}
      <div>{props.message}</div>
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      maxWidth: 500,
    },
    heading: {
      paddingBottom: theme.spacing(0.5),
    },
    close: {
      display: "block",
      marginLeft: "auto",
      padding: theme.spacing(0.5),
    },
  })
);
