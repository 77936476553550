import React from "react";
import { useHistory } from "react-router";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

import { AppHeader } from "../shared/AppHeader";
import { innerContainer, topMargin } from "../shared/styles";
import Typography from "@material-ui/core/Typography";

export const Article: React.FC = () => {
  const classes = useStyles();
  const {
    location: { state = {} },
    push,
  } = useHistory<any>();

  React.useEffect(() => {
    !state.header && push("/");
    window.scrollTo(0, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <React.Fragment>
      <AppHeader header={state.header} />
      <Container maxWidth="lg">
        <Grid container spacing={2} className={classes.root}>
          <Grid
            container
            item
            xs={12}
            md={4}
            className={classes.imageContainer}
          >
            <img src={state.link} alt={state.header} className={classes.img} />
          </Grid>
          <Grid container item xs={12} md={8}>
            <Typography
              variant="body2"
              color="textSecondary"
              component="p"
              dangerouslySetInnerHTML={{ __html: state.message || "" }}
            />
          </Grid>
        </Grid>
      </Container>
    </React.Fragment>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      ...topMargin,
      ...innerContainer,
      lineHeight: 1.5,
      "& h2": {
        fontWeight: 300,
        fontSize: "1.6rem",
        textTransform: "uppercase",
      },
      "& h3": {
        fontWeight: 300,
        fontSize: "1.2rem",
      },
    },
    imageContainer: {
      minHeight: 400,
      paddingTop: "30px !important",
    },
    img: {
      maxWidth: "100%",
      [theme.breakpoints.up("md")]: {
        maxHeight: 200,
      },
    },
  })
);
