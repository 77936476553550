import React from 'react';
import clsx from 'clsx';
import Grid from '@material-ui/core/Grid';
import GridList from '@material-ui/core/GridList';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import GridListTile from '@material-ui/core/GridListTile';
import GridListTileBar from '@material-ui/core/GridListTileBar';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';

import { AppSlider } from './AppSlider';
import { Intro } from './Intro';
import { Statements } from './Statements';
import { Team } from './Team';
import gyn_pap from '../../images/gyn_pap.jpg';
import hiv_testing from '../../images/hiv_testing.jpg';
import tele_health from '../../images/tele_health.jpg';
import immunizations from '../../images/immunizations.jpg';
import physical_exam from '../../images/physical_exam.jpg';
import womens_health from '../../images/womens_health.jpg';
import family_planning from '../../images/family_planning.jpg';
import pediatric_visit from '../../images/pediatric_visit.jpg';
import { appColors, innerContainer, topMargin } from '../shared/styles';
import { Articles } from '../articles/Articles';

interface TileContent {
    img: string;
    title: string;
    support?: string;
    featured?: boolean;
};

const services: TileContent[] = [
    {
        img: family_planning,
        title: 'Family planning',
        support: 'Services for teens, women and men',
        featured: true,
    },
    {
        img: physical_exam,
        title: 'Physical exams',
    },
    {
        img: tele_health,
        title: 'Telehealth services',
    },
    {
        img: immunizations,
        title: 'Immunizations',
        support: 'Services for children and adults',
    },
    {
        img: womens_health,
        title: "Women's health"
    },
    {
        img: gyn_pap,
        title: 'GYN/PAP'
    },
    {
        img: pediatric_visit,
        title: 'Pediatric visit',
        support: 'Service for ages 0-18',
    },
    {
        img: hiv_testing,
        title: '',
        support: 'HIV testing and counseling  <br /> Nexplanon insertion and removal <br /> Tuberculosis testing/PPD and more',
        featured: true,
    },
]


export const Home: React.FC<any> = (props) => {
    const classes = useStyles();
    return (
        <React.Fragment>
            <AppSlider {...props} />
            <Statements {...props} />
            <Intro {...props} />
            <Team {...props} />
            <Container maxWidth="lg">
                <Grid container spacing={1} className={classes.root}>
                    <Grid container item xs={12}>
                        <Typography variant="h4" component="h2" className={classes.header}>
                            Our Services also include:
                        </Typography>
                        <GridList cellHeight={180} className={classes.gridList}>
                            {services.map((tile: TileContent) => (
                                <GridListTile key={tile.img} className={clsx(classes.tile, {
                                    [classes.fullTile]: tile.featured,
                                })}>
                                    <img src={tile.img} alt={tile.title} />
                                    <GridListTileBar
                                        title={tile.title}
                                        subtitle={<Typography className={classes.supportText} dangerouslySetInnerHTML={{ __html: tile.support || '' }} />}
                                    />
                                </GridListTile>
                            ))}
                        </GridList>
                    </Grid>
                </Grid>
            </Container>
            <Articles minimal={true} />
        </React.Fragment>
    )
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            ...topMargin,
            ...innerContainer,
        },
        gridList: {
            width: '100%',
            margin: '0 auto !important',
        },
        tile: {
            width: 'calc(100% / 3) !important',
            [theme.breakpoints.up('md')]: {
                height: '260px !important',
            },
            '& >div': {
                borderRadius: 2,
            },
        },
        fullTile: {
            width: '100% !important',
        },
        supportText: {
            fontSize: '95%',
        },
        icon: {
            color: 'rgba(255, 255, 255, 0.54)',
        },
        header: {
            width: '100%',
            fontWeight: 900,
            textAlign: 'center',
            margin: '40px 0 10px',
            color: appColors.dark,
            textTransform: 'uppercase',
        }
    }),
);