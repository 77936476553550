import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";

import React from "react";
import {
  Container,
  createStyles,
  makeStyles,
  ThemeProvider,
} from "@material-ui/core";
import { useStateMachine } from "little-state-machine";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";

import { Footer } from "./components/Footer";
import { Resources } from "./components/resources/Resources";
import { Menu } from "./components/Menu";
import { Route } from "react-router-dom";
import { Career } from "./components/Career";
import { Reviews } from "./components/Reviews";
import { Login } from "./components/Login";
import { Admin } from "./components/Admin";
import { getData, sharedUrl } from "./helper";
import { Resource } from "./components/resources/Resource";
import { theme } from "./styleOveride";
import {
  AppSnackBar,
  AppSnackBarContent,
} from "./components/shared/AppSnackBar";
import {
  updateMessagesState,
  updateResourceState,
  updateUiMessage,
  updateArticles,
} from "./components/actions";
import { Home } from "./components/home/Home";
import { Article } from "./components/articles/Article";

const headerStyle = { fontWeight: 900, width: "100%" };

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      display: "flex",
      flexDirection: "column",
      height: "100%",
      "& span": {
        backgroundColor: "transparent !important",
      },
    },
    container: {
      flex: 1,
      padding: 0,
      "& h1": headerStyle,
      "& h2": headerStyle,
      "& h3": headerStyle,
    }
  })
);

const App: React.FC = () => {
  const classes = useStyles();
  const {
    state: { messages, uiMessage },
    actions,
  } = useStateMachine({
    updateMessagesState,
    updateResourceState,
    updateUiMessage,
    updateArticles,
  });

  React.useEffect(() => {
    getData(`${sharedUrl}messages`, true).then((res) => {
      if (res.success) {
        const checked = sessionStorage.getItem("messages");
        const closedMessages = checked ? JSON.parse(checked) : [];
        const newMessages = res.data?.map((message: AppSnackBarContent) => ({
          ...message,
          seen: closedMessages.includes(message._id),
        }));
        actions.updateMessagesState(newMessages);
      }
    });

    getData(`resources`)
      .then((res) => {
        if (res.success) {
          actions.updateResourceState(res.data);
        }
      })
      .catch((err) => {
        console.error(err);
      });

    getData(`articles`)
      .then((res) => {
        if (res.success) {
          actions.updateArticles({ data: res.data });
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <ThemeProvider theme={theme}>
      {messages.map((message: AppSnackBarContent) => (
        <AppSnackBar key={message?._id} {...message} />
      ))}
      <div className={classes.root}>
        <Menu />
        <Container maxWidth="xl" className={classes.container}>
          <Route exact={true} path="/" component={Home} />
          <Route path="/login" component={Login} />
          <Route path="/admin" component={Admin} />
          <Route path="/career" component={Career} />
          <Route path="/reviews" component={Reviews} />
          <Route path="/resources" component={Resources} />
          <Route path="/resources/:id" component={Resource} />
          <Route path="/article/:id" component={Article} />
        </Container>
        <Footer />
      </div>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={Boolean(uiMessage)}
        autoHideDuration={6000}
        onClose={() => actions.updateUiMessage({ message: null })}
      >
        <Alert severity={uiMessage?.type}>{uiMessage?.message}</Alert>
      </Snackbar>
    </ThemeProvider>
  );
};

export default App;
