import React from 'react';
import Grid from '@material-ui/core/Grid';
import Icon from '@material-ui/core/Icon';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { useStateMachine } from 'little-state-machine';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';

import { appColors } from '../shared/styles';
import { isOnAdminRoute, updateResourceState, updateUiMessage } from '../actions';
import { AddResource } from './AddResource';
import { postData } from '../../helper';


export const ResourceFolder: React.FC = () => {
    const classes = useStyles();
    const { state: { resources, auth } } = useStateMachine();
    const isCorrectUser = () => auth.loggedIn && isOnAdminRoute();

    return (
        <Grid container item xs={12}>
            {isCorrectUser() && (
                <React.Fragment>
                    <AddResource active="note" />
                    <AddResource active="pdf" />
                    <AddResource active="link" />
                </React.Fragment>

            )}
            {(resources.categories || []).map((data: any) => (
                <React.Fragment key={data.value}>
                    <Typography variant="h2" className={classes.subHeader}>
                        <span><Icon>{data?.icon}</Icon> {data.label}</span>
                    </Typography>
                    <Divider className={classes.divider} />
                    {(resources.data || []).filter((opt: any) => opt.parent === data.value && opt.type === 'message').map((msg: any) => (
                        <Item key={msg._id} isCorrectUser={isCorrectUser()} {...msg} />
                    ))}
                    <List className={classes.list}>
                        {(resources.data || []).filter((opt: any) => opt.parent === data.value && opt.type === 'link').map((link: any) => (
                            <Item key={link._id} isCorrectUser={isCorrectUser()} icon={data.icon} {...link} />
                        ))}
                    </List>
                </React.Fragment>
            ))}
        </Grid>
    );
}

const Item = ({ isCorrectUser, type, _id, header, text, icon, link }: any) => {
    const classes = useStyles();
    const [loading, setLoading] = React.useState<string | null>(null);
    const { state: { resources }, actions } = useStateMachine({
        updateResourceState,
        updateUiMessage
    });

    const deleteItem = (id: string) => {
        setLoading(id)
        postData(`resources/${id}`, {}, 'delete').then(res => {
            if (res.success) {
                const updatedResources = resources;
                updatedResources.data = updatedResources.data.filter((data: any) => data._id !== id);
                actions.updateResourceState(updatedResources);
                setLoading(null);
                actions.updateUiMessage({ message: { message: 'Success', type: 'success' } });
            } else
                actions.updateUiMessage({ message: { message: 'Error, try again', type: 'error' } });
        }).catch(err => {
            setLoading(null);
            actions.updateUiMessage({ message: { message: 'Error, try again', type: 'error' } });
        });
    }

    const deleteBtn = (id: string) => (
        isCorrectUser ? <Icon className={classes.deleteButton} onClick={() => deleteItem(id)}>{loading === id ? 'loop' : 'delete'}</Icon> : null
    );

    const getType = () => type === 'message' ? (
        <React.Fragment key={_id}>
            <Typography variant="h3" className={classes.textHeader}>
                <span>{header}</span>  {isCorrectUser && deleteBtn(_id)}
            </Typography>
            <Typography variant="body2">
                {text}
            </Typography>
        </React.Fragment>
    ) : (
            <ListItem key={_id} className={classes.listData} button onClick={() => window.open(link, '_blank')}>
                <ListItemIcon>
                    <Icon>{icon}</Icon>
                </ListItemIcon>
                <ListItemText primary={header} />
                {isCorrectUser && (
                    <ListItemSecondaryAction onClick={() => deleteItem(_id)}>
                        <IconButton edge="end" aria-label="delete">
                            <Icon>delete</Icon>
                        </IconButton>
                    </ListItemSecondaryAction>
                )}
            </ListItem>
        )
    return getType();
}


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        divider: {
            width: '100%',
            marginBottom: 20,
        },
        subHeader: {
            display: 'flex',
            marginTop: 30,
            lineHeight: 'inherit',
            color: appColors.medium,
            textTransform: 'uppercase',
            fontSize: '200% !important',
            fontWeight: '100 !important' as any,
        },
        textHeader: {
            lineHeight: 2,
            display: 'flex',
            color: appColors.light,
            fontSize: '120% !important',
            textTransform: 'capitalize',
        },
        list: {
            width: '100%',
        },
        listData: {
            display: 'flex',
            flexDirection: 'row',
            padding: '8px 30px',
            '& div': {
                color: appColors.dark,
                textTransform: 'capitalize',
                '& span': {
                    fontWeight: 'bold',
                },
            }
        },
        deleteButton: {
            marginLeft: 'auto',
            cursor: 'pointer',
        },
        accordion: {

        }
    }),
);