import React, { useEffect } from 'react';
import clsx from 'clsx';
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Collapse from '@material-ui/core/Collapse';
import CardMedia from '@material-ui/core/CardMedia';
import CardHeader from '@material-ui/core/CardHeader';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CardContent from '@material-ui/core/CardContent';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ArrowForwardIcon from '@material-ui/icons/ArrowForwardOutlined';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

import { appColors, buttonStyle } from './styles';
import thumbnail from '../../images/thumbnail.jpg';
import { Appointment } from './Appointment';

export const TeamCard: React.FC<any> = () => {
    const [expanded, setExpanded] = React.useState(false);
    const [showDialog, setShowDialog] = React.useState(false);
    const props = { expanded };
    const classes = useStyles(props);

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    useEffect(() => {
        if (expanded) {
            setTimeout(() => {
                // document.getElementById('card-content')?.scrollIntoView({ behavior: 'smooth' });
            }, 100);
        }
    }, [expanded])

    return (
        <Grid item xs={12} md={expanded ? 12 : 6} lg={expanded ? 12 : 4}>
            <Card className={classes.root}>
                <CardHeader
                    title={<b className={classes.qualifier}><span>Dr.</span> Mariam Salahou-Adeyanju <span>CRNP</span> </b>}
                    className={classes.header}
                    action={
                        <IconButton
                            className={clsx(classes.expand, {
                                [classes.expandOpen]: expanded,
                            })}
                            onClick={handleExpandClick}
                            aria-expanded={expanded}
                            aria-label="show more">
                            <ExpandMoreIcon />
                        </IconButton>
                    }
                />
                <div className={expanded ? classes.content : ''}>
                    <div className={expanded ? classes.userImage : ''}>
                        <CardMedia
                            image={thumbnail}
                            title="Mariam Salahou-Adeyanju"
                            className={classes.media}
                        />
                        <CardContent className={classes.cardContent}>
                            <Button
                                variant="contained"
                                endIcon={<ArrowForwardIcon />}
                                onClick={() => setShowDialog(!showDialog)}>
                                Schedule appointment
                            </Button>
                        </CardContent>
                    </div>
                    <Collapse in={expanded} timeout={0} unmountOnExit>
                        <CardContent id="card-content" className={classes.cardTextContent}>
                            <Typography paragraph>
                                An African-born, Mariam Salahou-Adeyanju CRNP migrated to the United in 1987, arriving in New York,
                                where she started her Education in nursing after completing a first bachelor's degree in Education.
                                Mariam graduated with an Associate degree in nursing from Queensborough, New York, in 1994,
                                and then attended the University of Massachusetts, graduating with Bachelors of nursing in
                                2001 and a Master's degree as a Family nurse practitioner in 2004.
                        </Typography>
                            <Typography paragraph>
                            She obtained her Doctor of Nursing practice at Grand Canyon University in June of 2021. In 2012, she founded Fademos Family Practice in Philadelphia, Pennsylvania, with her husband.
                            <br />Mariam is a board-certified nurse practitioner and a
                            member of the American Association of Nurse Practitioner and Sigma Theta tau.
                        </Typography>
                            <Typography paragraph>
                                Mariam has worked in primary care settings since graduation and loves serving patients
                                from birth until older adults. She founded Fademos Family Practice to bridge the gap in
                                health care for vulnerable and underserved populations. The clinic offers reduced and
                                affordable care to all patients, regardless of age, gender, and race. Decreasing health
                                disparities is the goal of the practice.
                        </Typography>
                            <Typography paragraph>
                                Her husband, Fransome Adeyanju is a past parole officer,
                                an African-born also whom she met in the United.
                                He graduated from Wichita University, Kansas.
                        </Typography>
                        </CardContent>
                    </Collapse>
                </div>
                <Appointment showDialog={showDialog} setShowDialog={() => setShowDialog(!showDialog)} />
            </Card>
        </Grid>

    );
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            opacity: 0.9,
            maxWidth: 345,
            marginTop: 20,
            minWidth: '100%',
            backgroundColor: appColors.light,
        },
        header: {
            fontSize: 18,
            color: '#fff',
            fontWeight: 900,
            letterSpacing: 1,
        },
        media: {
            height: 0,
            paddingTop: '80%',
            [theme.breakpoints.up('md')]: {
                paddingTop: (props: any) => props.expanded ? '30%' : '80%',
                backgroundSize: (props: any) => props.expanded ? 'inherit' : 'cover',
            },
        },
        expand: {
            transform: 'rotate(0deg)',
            marginLeft: 'auto',
            transition: theme.transitions.create('transform', {
                duration: theme.transitions.duration.shortest,
            }),
        },
        expandOpen: {
            transform: 'rotate(180deg)',
        },
        cardContent: {
            textAlign: 'center',
            '& button': buttonStyle()
        },
        cardTextContent: {
            color: '#FFF',
        },
        qualifier: {
            fontSize: 24,
            '& span': {
                color: appColors.dark,
            }
        },
        content: {
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            [theme.breakpoints.up('md')]: {
                flexDirection: 'row',
            },
        },
        userImage: {
            padding: 20,
            minWidth: '30%',
            '& div:first-of-type': {
                paddingTop: '100%',
            }
        }
    }),
);