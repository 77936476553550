import React from 'react';
import { Grid } from '@material-ui/core';
import { useForm } from "react-hook-form";
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';

import { appColors, buttonStyle, innerContainer, topMargin } from './shared/styles';
import { getData, postData, sharedUrl } from '../helper';
import { useStateMachine } from 'little-state-machine';
import { updateMessagesState, updateUiMessage } from './actions';
import { AppSnackBarContent, Message } from './shared/AppSnackBar';

interface FormContent {
    header: string;
    message: string;
}

export const AddMessage: React.FC<any> = (props) => {
    const classes = useStyles();
    const [loading, setLoading] = React.useState(false);
    const [deleteing, setDeleteing] = React.useState(false);
    const [showErrorMsg, setShowErrorMsg] = React.useState(false);
    const { state: { messages }, actions } = useStateMachine({
        updateMessagesState,
        updateUiMessage
    });
    const { register, handleSubmit, errors, reset } = useForm<FormContent>();


    const onSubmit = (data: FormContent) => {
        setShowErrorMsg(false);
        setLoading(true);
        postData(`${sharedUrl}messages`, data, 'post', true)
            .then(data => {
                if (data.success) {
                    reset();
                    setLoading(false);
                    actions.updateMessagesState({ ...data.message, seen: false });
                    actions.updateUiMessage({ message: { message: 'Success', type: 'success' } });
                } else {
                    setLoading(false);
                    setShowErrorMsg(true);
                    actions.updateUiMessage({ message: { message: 'Error, try again', type: 'error' } });
                }
            }).catch(error => {
                setLoading(false);
                setShowErrorMsg(true);
                actions.updateUiMessage({ message: { message: 'Error, try again', type: 'error' } });
            });
    };

    const onDelete = (id: string) => {
        setShowErrorMsg(false);
        setDeleteing(true);
        getData(`${sharedUrl}messages/${id}`, true, 'delete')
            .then(data => {
                if (data.success) {
                    const remainingMessage = messages.filter((message: AppSnackBarContent) => message._id !== id);
                    actions.updateMessagesState(remainingMessage);
                    setDeleteing(false);
                    actions.updateUiMessage({ message: { message: 'Success', type: 'success' } });
                } else
                    actions.updateUiMessage({ message: { message: 'Error, try again', type: 'error' } });
            }).catch(error => {
                setShowErrorMsg(true);
                actions.updateUiMessage({ message: { message: 'Error, try again', type: 'error' } });
            });
    };

    return (
        <Container id="add-career" maxWidth="sm">
            <form onSubmit={handleSubmit(onSubmit)}>
                <Grid container spacing={1} className={classes.root}>
                    <Grid container item xs={12}>
                        <Typography variant="h6" component="h3" className={classes.fullWidthText}>
                            Add Message
                        </Typography>
                    </Grid>
                    <Grid container item xs={12}>
                        {showErrorMsg && (
                            <Typography variant="caption" className={classes.errorMessage}>Error, login again and retry!</Typography>
                        )}
                    </Grid>
                    <Grid container item xs={12}>
                        <input name="header" ref={register({ required: false })} className={classes.input} placeholder="Header" disabled={loading} />
                    </Grid>
                    <Grid container item xs={12}>
                        <textarea name="message" ref={register({ required: true })} className={classes.input} placeholder="Message" disabled={loading} />
                        {errors.message && (
                            <Typography variant="caption" className={classes.errorText}>Field is required</Typography>
                        )}
                    </Grid>
                    <Button
                        type="submit"
                        disabled={loading}
                        variant="contained"
                        className={classes.button}>
                        Post Message
                    </Button>
                </Grid>
            </form>

            {deleteing && <LinearProgress />}
            {messages.map((message: AppSnackBarContent) => (
                <div key={message._id} className={classes.message}>
                    <Message {...message} onDelete={onDelete} />
                </div>
            ))}
        </Container>
    );
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            ...topMargin,
            ...innerContainer,
        },
        fullWidthText: {
            margin: '15px 0',
            color: appColors.dark,
            textTransform: 'uppercase',
        },
        input: {
            width: '100%',
            padding: 10,
            marginBottom: 10,
        },
        errorText: {
            fontSize: 10,
            color: 'red',
        },
        errorMessage: {
            fontSize: 14,
            color: 'red',
        },
        button: {
            ...buttonStyle(),
            marginLeft: 'auto',
            marginBottom: 40,
        },
        message: {
            margin: '10px 0',
            background: '#000',
            borderRadius: 5,
            padding: 5,
            color: '#fff',
            '& svg': {
                color: 'red'
            }
        }
    }),
);