export const tokenName = "_fademos_fp_token_";

export const url =
  /* process.env.NODE_ENV === "development"
    ? "http://localhost:5001/jollo-prod/us-central1/api/fademos/"
    : */ "https://us-central1-jollo-prod.cloudfunctions.net/api/fademos/";
export const sharedUrl =
  /* process.env.NODE_ENV === "development"
    ? "http://localhost:5001/jollo-prod/us-central1/api/"
    :  */"https://us-central1-jollo-prod.cloudfunctions.net/api/";

export async function postData(
  urlAddress: string,
  data = {},
  method = "post",
  usefullAddress = false
) {
  const response = await fetch(
    usefullAddress ? urlAddress : `${url}${urlAddress}`,
    {
      method: method.toLocaleUpperCase(),
      mode: "cors",
      headers: {
        owner: "fademos",
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem(tokenName)}`,
      },
      body: JSON.stringify(data),
    }
  );
  if (response.status === 401) {
    localStorage.removeItem(tokenName);
  }

  return response.json();
}

export async function getData(
  urlAddress: string,
  usefullAddress = false,
  method = "get"
) {
  const response = await fetch(
    usefullAddress ? urlAddress : `${url}${urlAddress}`,
    {
      method: method.toLocaleUpperCase(),
      headers: {
        owner: "fademos",
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem(tokenName)}`,
      },
    }
  );
  if (response.status === 401) {
    localStorage.removeItem(tokenName);
  }

  return response.json();
}

export const setLocalStorage = (value: string) =>
  localStorage.setItem(tokenName, value);

export const isLoggedIn = () => Boolean(localStorage.getItem(tokenName));

export const eMailPattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const editorOptions = {
  options: ["inline", "blockType", "list", "textAlign", "link"],
  inline: {
    inDropdown: false,
    options: ["italic", "underline", "strikethrough"],
  },
  blockType: {
    inDropdown: false,
    options: ["Normal", "H2", "H3", "H4", "H5", "H6", "Blockquote"],
  },
  list: {
    options: ["unordered", "ordered"],
  },
};
