import React from 'react';
import { AppBar, Grid, Tab, Tabs } from '@material-ui/core';
import { useHistory } from "react-router-dom";
import { useStateMachine } from "little-state-machine";
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

import { appColors, innerContainer, topMargin } from './shared/styles';
import { Career } from './Career';
import { Reviews } from './Reviews';
import { updateAuthState } from './actions';
import { AddAdmin } from './AddAdmin';
import { Resources } from './resources/Resources';
import { AddMessage } from './AddMessage';
import { Articles } from './articles/Articles';

export const Admin: React.FC<any> = (props) => {
    const classes = useStyles();
    const history = useHistory();
    const [value, setValue] = React.useState(0);
    const { state } = useStateMachine(updateAuthState);

    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setValue(newValue);
    };

    React.useEffect(() => {
        if (!state.auth.loggedIn) {
            history.replace('/login');
        }
        setTimeout(() => {
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        }, 1);
    }, [history, state.auth]);

    const componentToDisplay = () => {
        switch (value) {
            case 1:
                return <Reviews />;
            case 2:
                return <Resources />;
            case 3:
                return <Articles />;
            case 4:
                return <AddMessage />;
            case 5:
                return <AddAdmin />;
            default:
                return <Career />;
        }
    }

    return (
        <Container maxWidth="lg">
            <Grid container spacing={1} className={classes.root}>
                <Grid container item xs={12}>
                    <Typography variant="h4" component="h3" className={classes.fullWidthText}>
                        Admin
                    </Typography>
                </Grid>
            </Grid>
            <div className={classes.tabsRoot}>
                <AppBar position="static" className={classes.tabs}>
                    <Tabs value={value} variant="scrollable" scrollButtons="on" onChange={handleChange}>
                        <Tab label="Career" />
                        <Tab label="Reviews" />
                        <Tab label="Resources" />
                        <Tab label="Articles" />
                        <Tab label="Messages" />
                        <Tab label="Add Admin" />
                    </Tabs>
                </AppBar>
                {componentToDisplay()}
            </div>
        </Container>
    );
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            ...topMargin,
            ...innerContainer,
        },
        tabsRoot: {
            flexGrow: 1,
            backgroundColor: theme.palette.background.paper,
        },
        tabs: {
            backgroundColor: appColors.light,
            '& >div >div >span': {
                height: 5,
                backgroundColor: appColors.dark,
            },
        },
        fullWidthText: {
            margin: '15px 0',
            color: appColors.dark,
            textTransform: 'uppercase',
        },
        input: {
            width: '100%',
            padding: 10,
            marginBottom: 10,
        },
        errorText: {
            fontSize: 10,
            color: 'red',
        },
        errorMessage: {
            fontSize: 14,
            color: 'red',
        },
        button: {
            border: 'none',
            background: '#0077b6',
            color: '#fff',
            padding: 10,
            fontWeight: 600,
            textTransform: 'capitalize',
            borderRadius: 5,
            marginLeft: 'auto',
            marginRight: 5,
            minWidth: 100,
            '&:hover': {
                background: '#00b4d8',
            },
        },
    }),
);