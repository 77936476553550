import React, { Fragment } from "react";
import { Grid } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import Button from "@material-ui/core/Button";
import Skeleton from "@material-ui/lab/Skeleton";
import DeleteIcon from "@material-ui/icons/Delete";
import Container from "@material-ui/core/Container";
import { useStateMachine } from "little-state-machine";
import Typography from "@material-ui/core/Typography";
import CardContent from "@material-ui/core/CardContent";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

import {
  appColors,
  buttonStyle,
  innerContainer,
  topMargin,
} from "./shared/styles";
import { postData, url } from "../helper";
import { AddCareer } from "./AddCareer";
import { NothingHere } from "./shared/NothingHere";
import { isOnAdminRoute, updateAuthState } from "./actions";
import { AppHeader } from "./shared/AppHeader";

interface CareerContent {
  _id: string;
  position: string;
  requirements: string;
  introduction: string;
}

function Loader() {
  return (
    <Grid container item xs={12}>
      <Skeleton variant="text" width="100%" />
      <Skeleton variant="rect" width="100%" height={118} />
      <Skeleton variant="rect" width="100%" />
      <Skeleton variant="text" width="100%" />
    </Grid>
  );
}

export const Career: React.FC<any> = (props) => {
  const classes = useStyles();
  const [careers, setCareers] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const { state } = useStateMachine(updateAuthState);
  const [showErrorMsg, setShowErrorMsg] = React.useState<null | string>(null);

  React.useEffect(() => {
    fetchCareers();
  }, []);

  function fetchCareers() {
    fetch(`${url}jobs`)
      .then((response) => response.json())
      .then((data) => {
        setCareers(data.reverse());
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  }

  function onDelete(id: string) {
    setShowErrorMsg(null);
    postData(`jobs/${id}`, {}, "delete")
      .then((data) => {
        if (data.success) {
          const newCareers = careers.filter(
            (career: CareerContent) => career._id !== id
          );
          setCareers([...newCareers]);
        } else {
          setLoading(false);
          setShowErrorMsg(id);
        }
      })
      .catch((error) => {
        setLoading(false);
        setShowErrorMsg(id);
      });
  }

  const header = (position: string, id: string) => (
    <div className={classes.header}>
      <Typography variant="h2" gutterBottom>
        {position}
      </Typography>
      {state.auth.loggedIn &&
        isOnAdminRoute() &&
        (loading ? (
          <Typography variant="caption">'Please wait...'</Typography>
        ) : (
          <DeleteIcon style={{ fontSize: 40 }} onClick={() => onDelete(id)} />
        ))}
    </div>
  );

  function career(arg: CareerContent) {
    return (
      <Card key={arg._id} variant="outlined" className={classes.post}>
        <CardContent>
          {showErrorMsg === arg._id && (
            <Typography variant="caption" gutterBottom>
              Post could not be deleted, login again and retry!
            </Typography>
          )}
          {header(arg.position, arg._id)}
          {arg.introduction.length > 20 && (
            <div dangerouslySetInnerHTML={{ __html: arg.introduction }} />
          )}
          <Typography variant="caption" display="block" gutterBottom>
            Interested: send your application to info@fademosfp.com or call
            215-613-5069 for further enquiries.
          </Typography>
        </CardContent>
      </Card>
    );
  }

  return (
    <Fragment>
      <AppHeader header="Open positions" />
      <Container maxWidth="lg" id="resources">
        <Grid container spacing={1} className={classes.root}>
          <Grid container item xs={12}>
            {state.auth.loggedIn && isOnAdminRoute() && (
              <Button
                variant="contained"
                className={classes.buttonStyle}
                onClick={() => {
                  document.getElementById("add-career")?.scrollIntoView({
                    behavior: "smooth",
                  });
                }}
              >
                Add Career
              </Button>
            )}
          </Grid>
          {!loading && careers.length === 0 && (
            <NothingHere message="There are no positions available at the moment" />
          )}
          {loading
            ? [1, 2].map((v) => <Loader key={v} />)
            : careers.map((data) => career(data))}
        </Grid>
      </Container>
      {state.auth.loggedIn && isOnAdminRoute() && (
        <AddCareer fetchCareer={fetchCareers} />
      )}
    </Fragment>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      ...topMargin,
      ...innerContainer,
      "& >div": {
        margin: "10px 0",
        display: "flex",
        justifyContent: "space-between",
      },
    },
    textCenter: {
      width: "100%",
      marginBottom: 30,
      textAlign: "center",
    },
    post: {
      marginBottom: 15,
      padding: "20px !important",
      borderRadius: 5,
      lineHeight: 1.5,
      borderColor: appColors.light,
      "& h2": {
        fontWeight: 300,
        fontSize: "1.6rem",
        textTransform: "uppercase",
      },
      "& h3": {
        fontWeight: 300,
        fontSize: "1.2rem",
      },
      "& .body1": {
        minWidth: "100%",
        marginBottom: 10,
        paddingBottom: 10,
        borderBottomStyle: "dashed",
        borderBottom: `solid 1px ${appColors.light}`,
      },
    },
    header: {
      width: "100%",
      display: "flex",
      borderBottomStyle: "dashed",
      justifyContent: "space-between",
      borderBottom: `solid 1px ${appColors.light}`,
      "& >svg": {
        fontSize: "30px !important",
        cursor: "pointer",
        color: appColors.dark,
        "&:hover": {
          opacity: 0.7,
        },
      },
    },
    buttonStyle: buttonStyle(),
  })
);
