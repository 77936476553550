import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import ListItem from '@material-ui/core/ListItem';
import { appColors } from './styles';

const officeHours = [
    {
        days: 'Monday',
        hours: '8am - 8pm',
    },
    {
        days: 'Tuesday - Thursday',
        hours: '8am - 5pm',
    },
    {
        days: 'Friday',
        hours: '8am - 3pm',
    },
    {
        days: 'Major holidays.',
        hours: 'Closed',
    }
];

export const Hours: React.FC<any> = (props) => {
    const classes = useStyles();

    function displayHours(hoursArr: { days: string; hours: string; }[]) {
        return (
            <div>
                {hoursArr.map(({ days, hours }, index) => (
                    <ListItem key={index} disableGutters divider className={classes.list}>
                        <Typography variant="overline" className={classes.listText}>{days}</Typography>
                        <Typography variant="overline" className={classes.bold}>{hours}</Typography>
                    </ListItem>
                ))}
            </div>
        );
    }

    return (
        <div className={classes.info}>
            <Typography variant="h6" component="h3" gutterBottom>
                Hours
                    </Typography>
            <Typography variant="body1" className={classes.header}>
                Office hours:
            </Typography>
            {displayHours(officeHours)}
            <Typography paragraph variant="caption" className={classes.bold}>
                First saturday of the month by appointment only.
            </Typography>
            <Typography variant="caption" className={classes.bold}>
                Appointments are preferred, but they are not necessary.
            </Typography>
        </div>
    );
}

const textContainer = {
    minHeight: 100,
    color: '#ffffff',
    padding: '1em !important',
    '& h3': {
        fontWeight: 900,
        textAlign: 'left',
        marginBottom: 20,
    },
    '& p': {
        textAlign: 'left',
    }
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        info: {
            ...textContainer,
            width: '100%',
            flexDirection: 'column',
            background: appColors.dark,
        },
        list: {
            padding: 0,
            justifyContent: 'space-between',
        },
        table: {
            width: '100%',
        },
        listText: {
            fontSize: 16,
        },
        header: {
            fontSize: 15,
            fontWeight: 900,
        },
        bold: {
            fontWeight: 900,
            fontSize: '12px !important',
        }
    }),
);