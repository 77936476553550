import './AddResource.css';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import React from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useDropzone } from 'react-dropzone';
import Button from '@material-ui/core/Button';
import LinearProgress from '@material-ui/core/LinearProgress';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Icon from '@material-ui/core/Icon';
import Typography from '@material-ui/core/Typography';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import TextField from '@material-ui/core/TextField';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

import { AppSelect } from '../shared/AppSelect';
import { appColors, buttonStyle } from '../shared/styles';
import { postData } from '../../helper';
import { useStateMachine } from 'little-state-machine';
import { updateResourceState, updateUiMessage } from '../actions';

export const AddResource: React.FC<any> = ({ active = 'pdf' }) => {
    const classes = useStyles();
    const [file, setFile] = React.useState<any>(null);
    const [loading, setLoading] = React.useState(false);
    const [canSubmit, setCanSubmit] = React.useState(active === 'pdf' ? false : true);
    const { getRootProps, getInputProps } = useDropzone({
        minSize: 0,
        maxFiles: 1,
        multiple: false,
        accept: 'application/pdf',
        onDrop: (acceptedFiles: any) => {
            setFile(acceptedFiles[0]);
            setCanSubmit(true);
        }
    });
    const { register, handleSubmit, control, errors, reset } = useForm();
    const { state: { resources }, actions } = useStateMachine({
        updateResourceState,
        updateUiMessage
    });

    const toBase64 = (file: any) => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });

    async function onSubmit({ message, header, link, resourceType }: any) {
        const data = {
            header,
            text: message,
            link,
            parent: resourceType,
            file: file ? await toBase64(file) : null,
        };
        setLoading(true);
        postData('resources', data).then(res => {
            if (res.success) {
                reset();
                const updatedResources = resources;
                updatedResources.data.push({ ...res.data, ...(JSON.parse(res.data.data)) });
                actions.updateResourceState(updatedResources);
                actions.updateUiMessage({ message: { message: 'Success', type: 'success' } });
                if (active === 'pdf') {
                    setFile(null);
                    setCanSubmit(false);
                }
            } else {
                actions.updateUiMessage({ message: { message: 'Error, try again', type: 'error' } });
            }
            setLoading(false);
        }).catch(err => {
            actions.updateUiMessage({ message: { message: 'Error, try again', type: 'error' } });
            setLoading(false);
        });
    }

    const getType = () => {
        switch (active) {
            case 'link':
                return 'Link';
            case 'note':
                return 'Note';
            default:
                return 'Pdf';
        }
    }


    return (
        <Accordion className={classes.accordion}>
            <AccordionSummary className={classes.accordionHeader} expandIcon={<Icon>expand_more</Icon>}>
                <Typography variant="h3" className={classes.textHeader}>
                    Add {getType()}
                </Typography>
            </AccordionSummary>
            {loading && <LinearProgress className={classes.fullWidth} />}
            <AccordionDetails className={classes.outerContainer}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={6}>
                            <Controller
                                name="resourceType"
                                control={control}
                                defaultValue={resources.categories[0].value}
                                rules={{ required: true }}
                                render={({ onChange, value }) => <AppSelect
                                    options={resources?.categories || []}
                                    value={value}
                                    error={Boolean(errors.resourceType)}
                                    label="Resource category*"
                                    onChange={onChange} />}
                            />
                            {errors.resourceType && <span className={classes.errorText}>This field is required</span>}
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                name="header"
                                className={classes.fullWidth}
                                error={Boolean(errors.header)}
                                inputRef={register({ required: (getType() === 'Pdf' || getType() === 'Link') })}
                                label={getType() === 'Note' ? 'Header' : 'Resource name*'}
                                variant="outlined" />
                            {errors.header && <span className={classes.errorText}>This field is required</span>}
                        </Grid>
                        {getType() === 'Note' && (
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    name="message"
                                    className={classes.fullWidth}
                                    error={Boolean(errors.message)}
                                    inputRef={register({ required: true })}
                                    label="Note *" variant="outlined" />
                                {errors.message && <span className={classes.errorText}>This field is required</span>}
                            </Grid>
                        )}
                        {getType() === 'Link' && (
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    name="link"
                                    className={classes.fullWidth}
                                    error={Boolean(errors.link)}
                                    inputRef={register({ required: true })}
                                    label="Link to 3rd party resources*" variant="outlined" />
                                {errors.link && <span className={classes.errorText}>This field is required</span>}
                            </Grid>
                        )}
                        {getType() === 'Pdf' && (
                            <Grid item xs={12}>
                                <div className="file-container">
                                    <div {...getRootProps({ className: 'dropzone' })}>
                                        <input {...getInputProps()} />
                                        <p>Drag 'n' drop some files here, or click to select files</p>
                                    </div>
                                    <div className="aside">
                                        {file && <p>{file.path} - {file.size} bytes</p>}
                                    </div>
                                </div>
                            </Grid>
                        )}
                    </Grid>
                    <Button
                        type="submit"
                        variant="contained"
                        disabled={loading || !canSubmit}
                        className={classes.button}>
                        Save
                    </Button>
                </form>
            </AccordionDetails>
        </Accordion>
    );
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        button: {
            ...buttonStyle(),
            marginLeft: 'auto',
            display: 'block',
            marginTop: 20,
            marginBottom: 20,
        },
        accordion: {
            marginBottom: 30,
            width: '100%',
        },
        errorText: {
            fontSize: '70%',
            color: 'red',
            letterSpacing: 1,
        },
        accordionHeader: {
            background: appColors.dark,
            '& .material-icons': {
                color: '#fff',
            }
        },
        fullWidth: {
            width: '100%',
        },
        textHeader: {
            lineHeight: 2,
            display: 'flex',
            color: '#fff',
            fontWeight: '300 !important' as any,
            fontSize: '120% !important',
            textTransform: 'capitalize',
        },
        outerContainer: {
            paddingTop: theme.spacing(2),
        }
    }),
);