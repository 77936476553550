import React from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import RoomTwoToneIcon from '@material-ui/icons/RoomTwoTone';
import EmailOutlinedIcon from '@material-ui/icons/EmailOutlined';
import ArrowForwardIcon from '@material-ui/icons/ArrowForwardOutlined';
import PhoneInTalkTwoToneIcon from '@material-ui/icons/PhoneInTalkTwoTone';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import LocalPrintshopTwoToneIcon from '@material-ui/icons/LocalPrintshopTwoTone';

import { Hours } from './shared/Hours';
import { appColors, buttonStyle, innerContainer, textContainer } from './shared/styles';
import { Appointment } from './shared/Appointment';
import { Link } from 'react-router-dom';

export const Footer: React.FC<any> = () => {
    const classes = useStyles();
    const [showDialog, setShowDialog] = React.useState(false);

    return (
        <div className={classes.root}>
            <Container maxWidth="lg">
                <Grid container className={classes.innerContainer} spacing={0}>
                    <Grid container item xs={12} md={6} className={classes.bottomPadding}>
                        <Hours />
                    </Grid>
                    <Grid container item xs={12} md={6} className={classes.bottomPadding}>
                        <div className={classes.contact}>
                            <Typography variant="h6" component="h3" gutterBottom>
                                Contact us
                            </Typography>
                            <div>
                                <PhoneInTalkTwoToneIcon />
                                <Typography variant="body1">
                                    215-613-5069
                                </Typography>
                            </div>
                            <div>
                                <LocalPrintshopTwoToneIcon />
                                <Typography variant="body1">
                                    215-613-6809
                                </Typography>
                            </div>
                            <div>
                                <EmailOutlinedIcon />
                                <Typography variant="body1">
                                    info@fademosfp.com
                                </Typography>
                            </div>
                            <div>
                                <RoomTwoToneIcon />
                                <Typography variant="body1">
                                    16 Old Ashton Road, Philadelphia, PA 19152
                                </Typography>
                            </div>

                            <Button
                                variant="contained"
                                endIcon={<ArrowForwardIcon />}
                                onClick={() => setShowDialog(!showDialog)}>
                                Schedule appointment
                            </Button>
                        </div>
                    </Grid>
                    <Grid container item xs={12} className={classes.center}>
                        <Typography variant="caption" className={classes.copyRight}>
                            Copyright © {(new Date().getFullYear())} Fademos
                        </Typography>
                        <Typography variant="caption">
                            <Link to="/login" className={classes.login}>Login</Link>
                        </Typography>
                    </Grid>
                </Grid>
            </Container>
            <Appointment showDialog={showDialog} setShowDialog={() => setShowDialog(!showDialog)} />
        </div>
    );
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            marginTop: 36,
            padding: '36px 0',
            background: appColors.light,
        },
        bottomPadding: {
            paddingBottom: 36,
        },
        paper: {
            width: '100%',
            padding: theme.spacing(2),
            textAlign: 'center',
            color: theme.palette.text.secondary,
        },
        innerContainer: {
            ...innerContainer,
        },
        contact: {
            ...textContainer,
            width: '100%',
            flexDirection: 'column',
            background: appColors.dark,
            '& > div': {
                display: 'flex',
                marginBottom: 15,
                '& svg': {
                    marginRight: 10
                },
                '& p': {
                    fontSize: 16,
                },
            },
            '& button': {
                ...buttonStyle(true),
                marginTop: 10,
            },
        },
        copyRight: {
            margin: '0 10px !important',
            color: '#FFF',
        },
        login: {
            color: '#FFF',
            marginLeft: 1,
            textDecoration: 'none',
            '&:hover': {
                textDecoration: 'underline',
            },
        },
        center: {
            justifyContent: 'center',
        },
    }),
);