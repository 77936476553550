import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from "react-router-dom";
import { createStore, StateMachineProvider } from 'little-state-machine';

import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { isLoggedIn } from './helper';

createStore({
    auth: {
        loggedIn: isLoggedIn(),
    },
    resources: {
        categories: [
            { label: 'Loading', value: 'null', icon: 'description', },
        ],
        displayData: [],
        messages: [],
    },
    articles: [],
    messages: [],
    uiMessage: null,
});

ReactDOM.render(
    <React.StrictMode>
        <BrowserRouter>
            <StateMachineProvider>
                <App />
            </StateMachineProvider>
        </BrowserRouter>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
