import React from 'react';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

import { appColors, innerContainer } from './styles';
import { Grid } from '@material-ui/core';

export const AppHeader: React.FC<{ header: string; }> = ({ header, children }) => {
    const classes = useStyles();

    return (
        <Box className={classes.root}>
            <Container maxWidth="lg">
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={8}>
                        <Typography variant="h4" component="h1" className={classes.fullWidthText}>
                            {header}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={4} className={classes.right}>
                        {children}
                    </Grid>
                </Grid>
            </Container>
        </Box>
    )
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            height: 200,
            display: 'flex',
            alignItems: 'center',
            backgroundColor: appColors.dark,
            ...innerContainer,
        },
        fullWidthText: {
            margin: 0,
            width: '100%',
            color: '#fff',
            textAlign: 'left',
            textTransform: 'uppercase',
        },
        right: {
            [theme.breakpoints.up('sm')]: {
                textAlign: 'right',
            },
        },
    }),
);