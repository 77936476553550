import React from 'react';
import { Grid } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import { useStateMachine } from 'little-state-machine';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

import { appColors, innerContainer, topMargin } from '../shared/styles';
import { updateAuthState } from '../actions';

export const Resource: React.FC<any> = (props) => {
    const classes = useStyles();
    const { state: {resources} } = useStateMachine(updateAuthState);
    const { match: { params } } = props;
    
    const article = resources.find((resource: any) => resource._id === params.id)
    
    return (
        <Container maxWidth="lg" id="resources">
            <Grid container spacing={1} className={classes.root}>
                <Grid container item xs={12}>
                    <Typography variant="h4" component="h3" className={classes.fullWidthText}>
                        {article.header}
                    </Typography>
                    <Avatar variant="square" alt={article.header} src={article.externalUrl} className={classes.image}/>
                </Grid>
                {article.message.length > 20 && <div dangerouslySetInnerHTML={{__html: article.message}} />}
            </Grid>
        </Container>
    );
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            ...topMargin,
            ...innerContainer,
        },
        fullWidthText: {
            color: appColors.dark,
            textTransform: 'uppercase',
        },
        image: {
            width: '100%',
            height: '50vh',
            margin: '20px 0'
        }
    }),
);