export const innerContainer = {
    paddingLeft: 20,
    paddingRight: 20,
};

export const topMargin = {
    marginTop: 20,
}

export const appColors = {
    lightest: '#B2E8F3',
    light: '#00b4d8',
    medium: '#0096c7',
    dark: '#0077b6',
}

export const textContainer = {
    minHeight: 100,
    color: '#ffffff',
    padding: '1em !important',
    '& h3': {
        fontWeight: 900,
        textAlign: 'left',
        marginBottom: 20,
    },
    '& p': {
        textAlign: 'left',
    }
};

const btnStateChange = (dark: boolean) => ({
    background: dark ? appColors.dark : appColors.medium,
});

export const buttonStyle = (dark = false) => ({
        color: '#fff',
        background: dark ? appColors.medium : appColors.dark,
        '&:focus': btnStateChange(dark),
        '&:hover': btnStateChange(dark),
        '&:active': btnStateChange(dark),
        '&:visited': btnStateChange(dark),
})